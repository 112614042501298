import { ButtonHTMLAttributes } from "react";
import buttonStyleClasses, { ButtonStyle } from "./buttonStyle";

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonStyle?: ButtonStyle;
}

export default function Button({
  children,
  buttonStyle = "primary",
  ...props
}: Props) {
  return (
    <button {...props} className={buttonStyleClasses(buttonStyle)}>
      {children}
    </button>
  );
}
