type TagArrayable = string | Tag | Tag[] | null;

export default class Tag {
  #tag: string;

  #length: number;

  #value: string | Tag[];

  static getStringValue(tag: string, input: TagArrayable): string | undefined {
    const foundTag = this.toTagArray(input).find((t) => t.tag === tag);
    if (typeof foundTag?.value === "string") {
      return foundTag.value;
    }

    return undefined;
  }

  static fromTagArray(tag: string, input: Tag[]): Tag {
    const { length } = [...this.toString(input)];
    return new this(tag, length, input);
  }

  static toTagArray(input?: TagArrayable): Tag[] {
    let tagList: Tag[] = [];

    if (typeof input === "string") {
      tagList = this.parse(input);
    } else if (input instanceof Tag) {
      tagList = [input];
    } else if (input != null) {
      tagList = input;
    }

    return tagList;
  }

  static fromString(tag: string, input: string): Tag {
    return new this(tag, [...input].length, input);
  }

  static toString(input: TagArrayable): string {
    return this.toTagArray(input)
      .map((tag) => tag.toString())
      .join("");
  }

  static debug(input: TagArrayable): void {
    this.traverse(input, (tag, path) => {
      const indent = "\t".repeat(path.length + 1);
      if (typeof tag.value === "string") {
        console.log(`${indent}${tag.tag} ${tag.length} ${tag.value}`);
      } else {
        console.log(`${indent}${tag.tag} ${tag.length}`);
      }
    });
  }

  static traverse(
    input: TagArrayable,
    func: (tag: Tag, path: Tag[]) => void,
    path: Tag[] = [],
  ) {
    this.toTagArray(input).forEach((tag) => {
      func(tag, path);
      if (typeof tag.value !== "string") {
        this.traverse(tag.value, func, [...path, tag]);
      }
    });
  }

  static parse(input?: string, path: string[] = []): Tag[] {
    const tags: Tag[] = [];

    if (input == null) {
      return tags;
    }

    const unicodeCodepoints = [...input];

    let index = 0;
    let currentTag: string;
    let currentLength: number;
    let currentValue: string | Tag[];

    while (index < unicodeCodepoints.length) {
      currentTag = unicodeCodepoints.slice(index, index + 2).join("");
      currentLength = parseInt(
        unicodeCodepoints.slice(index + 2, index + 4).join(""),
        10,
      );
      currentValue = unicodeCodepoints
        .slice(index + 4, index + currentLength + 4)
        .join("");

      const newPath = [...path, currentTag];
      if (this.#isTemplate(newPath)) {
        currentValue = this.parse(currentValue, newPath);
      }

      tags.push(new this(currentTag, currentLength, currentValue));

      index += currentLength + 4;
    }

    return tags;
  }

  static #isTemplate(path: string[]): boolean {
    const pathTags = path.map((p) => parseInt(p, 10));
    if (path.length === 1) {
      if (pathTags[0] >= 26 && pathTags[0] <= 51) {
        // Merchant Account Information
        return true;
      }

      if (pathTags[0] >= 80 && pathTags[0] <= 99) {
        // Unreserved Templates
        return true;
      }

      if (pathTags[0] === 62) {
        // Additional Data Field Template
        return true;
      }

      if (pathTags[0] === 64) {
        // Merchant Information Language Template
        return true;
      }
    } else if (path.length === 2) {
      if (pathTags[0] === 62 && pathTags[1] >= 50 && pathTags[1] <= 99) {
        // Payment System specific templates
        return true;
      }
    }

    return false;
  }

  private constructor(tag: string, length: number, value: string | Tag[]) {
    this.#tag = tag;
    this.#length = length;
    this.#value = value;

    if (tag.length !== 2) {
      throw new Error("tag must be 2 digits");
    }

    if (length < 0 || length > 99) {
      throw new Error("length can only be 0 to 99");
    }
  }

  get tag() {
    return this.#tag;
  }

  get length() {
    return this.#length;
  }

  get value() {
    return this.#value;
  }

  toString() {
    let value: string;

    if (typeof this.#value === "string") {
      value = this.#value;
    } else {
      value = this.#value.map((tag) => tag.toString()).join("");
    }

    return `${this.#tag}${this.#length.toString(10).padStart(2, "0")}${value}`;
  }
}
